import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

/**
 * Renders the preloader
 */
const SidebarCollapse = ({ items }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    }

    return (
        <li>
            <a href="#" onClick={toggle}>
                <i className="mdi mdi-archive"></i>
                <span> Saved Information </span>
                <span className="menu-arrow"></span>
            </a>
            <Collapse isOpen={isOpen}>
                <ul className="nav-second-level">
                    {
                        items.map((item, key) =>
                            <li key={key}>
                                <a href={item.url}>{item.label}</a>
                            </li>
                        )
                    }
                </ul>
            </Collapse>
        </li>
      )
}

export default SidebarCollapse;
